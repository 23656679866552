<template lang="pug">
  div
    base-navigation-tabs.mb-2(
      :items="links"
    )
    router-view(
      :customer-data="customerData"
    )
</template>
<script>
import customerRepository from '@/repositories/customer.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'
import searchDelay from '@/libs/searchDelay.extra'

const [customerGetVars, customerGetVarNames] = requestVars({ identifier: 'customer', data: 'customerData' })
const pushDelay = searchDelay(100)

export default {
  name: 'CustomerForcasts',
  components: {

  },
  created () {
    this.getCustomer()
  },
  data () {
    return {
      links: [
        { label: 'List', path: { name: 'customers.forecasts.table' } },
        { label: 'New', path: { name: 'customers.forecasts.create' } },
        { label: 'Sow', path: { name: 'customers.forecasts.sow-pigs' } },
      ],
      ...customerGetVars,
    }
  },
  methods: {
    getCustomer () {
      if (this.customerGetting) return
      const handler = new VueRequestHandler(this, customerGetVarNames)
      const repository = customerRepository.edit
      const { customerId } = this.$route.params
      handler.execute(repository, [customerId])
    },
  },
}
</script>
